<template>
   <div class="acoperirenationala">
		<page-header :item="header_image" />
		<div class="main-content">
			<div class="container">
				<div class="row">
					<div class="col-sm-12 col-md-5">
						<div class="title">Suntem prezenți chiar și în zona ta. Verifică disponibilitatea produselor în județul tău.</div>
						<div class="description">Indiferent de zona ta, oricare dintre produsele disponibile poate deveni imediat parte din casa ta.  Obține informații actualizate despre livrarea produselor și disponibilitatea stocurilor în județul tău.</div>
						<div class="county-select">
							<label for="select-county">Alege județul tău</label>
							<vue-select
								id="select-county"
								name="select-county"
								v-model="countySelected"
								:options="counties"
								:value-by="'county_name'"
								:label-by="'county_name'"
								close-on-select clear-on-select searchable
								:openDirection="'bottom'"
								:placeholder="'Alege un județ'"
								:search-placeholder="'Cauta judet'">
							</vue-select>
						</div>
					</div>
					<div class="col-sm-12 col-md-7">
						<img :src="require(`@/assets/images/acoperirenationala/${mapImage}.png`)" alt="Romania Map" />
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 col-md-8 col-lg-5">
						<div v-if="county.county_name" class="buttons-wrp">
							<router-link v-if="county.page_url" :to="'/magazin/'+county.page_url" tag="button" class="dark-custom-btn">Achiziție produse în {{ county.county_name }}</router-link>
							<button type="button" @click="showContactSection" class="dark-custom-btn">Consultanță tehnică în {{ county.county_name }}</button>
						</div>
					</div>
				</div>
				<div v-if="showContact">
					<div class="row">
						<div class="col-md-6 contact-title" ref="contactsection">Pentru consultanță tehnică în județul <span style='color:#97040c'>{{ county.county_name }}</span>, contactează-ne prin formularul de mai jos</div>
					</div>
					<div class="row">
						<div class="col-md-5">
							<div class="contact-description">
								Suntem aici să răspundem întrebărilor tale și să te ajutăm să te bucuri de produsele alese cât mai repede cu putință.
							</div>
							<div class="contact-description-info">
								Ai întrebări sau ai nevoie de ajutor?  Scrie-ne folosind formularul de mai jos și îți vom răspunde cât mai curând posibil, <span style="font-family:Raleway Bold">în maxim 24 de ore lucrătoare.</span>
							</div>
						</div>
						<div class="col-md-7">
							<contact :county_name="county.county_name" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
import Contact from "@/pages/acoperirenationala/Contact.vue";
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

export default {
	name: "AcoperireNationala",
	data() {
		return {
			// header_image: "",
			countySelected: "",
			counties: [],
			county: "",
			mapImage: "RomaniaMap",
			showContact: false,
		}
	},
	components: {
		"page-header": PageHeader,
		"vue-select": VueSelect,
		"contact": Contact,
	},
	methods: {
		showContactSection() {
			this.showContact = true;
			setTimeout(() => {
				this.$refs.contactsection.scrollIntoView({ behavior: "smooth", block: "start"});	
			}, 500);
		}
	},
	computed: {
		header_image() {
			let image = require("@/assets/images/acoperire_nationala_header.webp");
			return {
				title: 'PEFOC, distribuție și consultanță în toate județele României',
				path: image,
			}
		},
  
	},
	watch: {
		countySelected: function() {
			this.showContact = false;
			this.county = this.counties.find(county => county.county_name === this.countySelected);
			this.mapImage = this.county.county_name;
		},
	},
	created() {
		this.$store.dispatch("acoperirenationala/getCountiesInfo").then((response) => {
			this.counties = response;
			this.$store.commit("seo/setParams", {
				seo_title: "Vanzare seminee de firme si mesteri execut constructie sobe",
			});
		});
	}
}

</script>

<style>
.acoperirenationala {
	min-height: 1200px;
	background-color: #f4f4f4;
}

.main-content {
	padding-top: 40px;
}

.acoperirenationala .title {
	font-family: "Raleway Bold";
	font-size: 24px;
	line-height: 1.25;
	margin-bottom: 30px;
}

.acoperirenationala .description {
	font-family: "Montserrat";
	font-size: 15px;
	margin-bottom: 30px;
}

.acoperirenationala .county-select {
	font-family: "Raleway Bold";
	font-size: 15px;
	margin-bottom: 40px;
}

.acoperirenationala .county-select #select-county {
	border-radius: 20px;
}

.acoperirenationala .county-select #select-county div{
	color: #6a6666 !important;
}

.acoperirenationala .county-select #select-county:after {
	position: absolute;
	right: 10px;
}

.county-select .vue-select {
	width: 320px;
	height: 40px;
	border-radius: 20px;
	background-color: #fff;
}

.county-select .vue-select-header .icon.arrow-downward {
	margin-right: 15px;
}

.county-select .vue-input {
	padding: 7px 20px;
}

.county-select .vue-input input {
	font-size: 15px;
}

.county-select .vue-dropdown-item {
   padding-left: 20px;
}

.county-select .vue-dropdown-item.highlighted {
	background-color: #f3f3f3;
}

.county-select .vue-dropdown-item.selected.highlighted {
	pointer-events: none !important;
}

.buttons-wrp {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	margin-top: -80px;
	margin-bottom: 60px;
}

.buttons-wrp .dark-custom-btn {
	font-family: Montserrat;
	font-size: 17px;
	font-weight: bold;
	border-radius: 33px;
	background-color: #000;
	color: #fff;
	padding: 12px 30px;
	width: 100%;
	text-align: center;
}

.acoperirenationala .contact-title {
    font-family: Raleway Bold;
    font-size: 31px;
    line-height: 1;
    margin-bottom: 35px;
	scroll-margin: 70px;
}
.acoperirenationala .contact-description {
    font-family: Raleway Bold;
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 40px;
}
.acoperirenationala .contact-description-info {
	font-family: Raleway;
    font-size: 12px;
    line-height: 1.67;
    color: #646464;
	margin-bottom: 40px;
}

@media (max-width: 1200px) {
	.buttons-wrp {
		margin-top: 0;
	}
}
@media (max-width: 768px) {
	.buttons-wrp {
		margin-top: 40px;
	}
}
@media (max-width: 576px) {
	.acoperirenationala .county-select label{
		font-size: 20px;
	}
	.acoperirenationala .contact-title {
        font-size: 37px;
    }
}

</style>